// jQuery
import jquery from 'jquery';
window.jQuery = jquery;
window.$ = jquery;

// @fancyapps
require('@fancyapps/fancybox/dist/jquery.fancybox');

// require('./scripts/fancybox-min');

// jquery.maskedinput
require('jquery-mask-plugin/src/jquery.mask');

// jquery-nice-select
require('jquery-nice-select/js/jquery.nice-select');

require('./scripts/scripts');